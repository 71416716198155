<template>
	<div class="wrap__content">
		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['LimitManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">{{ $t('titles.create_new_limit') }}</div>
										<div class="content__title--subtext">
											{{ $t('titles.create_new_limit_sub') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<form method="POST" @submit.prevent="onSubmit">
									<!--<div class="form__line">
                    <div class="form__line--left">
                      <div class="form__line--title">
                        Наименование лимита
                      </div>
                    </div>
                    <div class="form__line--right">
                      <div class="col-xl-5">
                        <div class="form__line--input" :class="[errorMessage.messages && errorMessage.messages.name ? 'input--error' : '']">
                          <input type="text" class="input-linck" required="" name="name" value="" :placeholder="$t('headers.name')">
                        </div>
                        <div class="input-required" v-if="errorMessage.messages && errorMessage.messages.name"><p v-for="(massage, massageInd) in errorMessage.messages.name" :key="massageInd">{{ massage }}</p></div>
                      </div>
                    </div>
                  </div>-->

									<div class="row">
										<div class="col-xl-12">
											<div class="form__line">
												<div class="form__line--left"></div>
												<div class="form__line--right">
													<div class="form__limit--row">
														<div class="col-lg-12">
															<div class="row">
																<div class="col-lg-6">
																	<div class="form__checkbox--chapter">
																		<div class="form__limit--btn">
																			<!--  form__limit--success -->
																			<div class="form__limit--icon">
																				<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.873 13.706c.35.103.688.248 1.004.43a7.167 7.167 0 007.346 0 4.659 4.659 0 014.586 0 7.435 7.435 0 007.382 0 4.66 4.66 0 014.587 0 7.614 7.614 0 003.726 1.004 6.951 6.951 0 003.584-1.004 4.589 4.589 0 011.003-.43 1.791 1.791 0 10-1.003-3.44 7.974 7.974 0 00-1.792.806 3.727 3.727 0 01-3.763 0 8.314 8.314 0 00-8.134 0 3.78 3.78 0 01-3.798 0 8.314 8.314 0 00-8.134 0 3.726 3.726 0 01-3.763 0 7.975 7.975 0 00-1.791-.806 1.792 1.792 0 00-1.004 3.44h-.036zm32.25 14.477a7.974 7.974 0 00-1.791.806 3.727 3.727 0 01-3.763 0 8.312 8.312 0 00-8.134 0 3.781 3.781 0 01-3.798 0 8.312 8.312 0 00-8.134 0 3.728 3.728 0 01-3.763 0 7.975 7.975 0 00-1.792-.806 1.79 1.79 0 10-1.003 3.44c.35.103.687.247 1.003.43a7.167 7.167 0 007.346 0 4.658 4.658 0 014.587 0 7.435 7.435 0 007.382 0 4.658 4.658 0 014.586 0 7.614 7.614 0 003.727 1.003 6.951 6.951 0 003.583-1.003 4.587 4.587 0 011.004-.43 1.791 1.791 0 10-1.004-3.44h-.036zm0-8.958a7.97 7.97 0 00-1.791.806 3.726 3.726 0 01-3.763 0 8.313 8.313 0 00-8.134 0 3.78 3.78 0 01-3.798 0 8.313 8.313 0 00-8.134 0 3.727 3.727 0 01-3.763 0 7.97 7.97 0 00-1.792-.806 1.792 1.792 0 00-2.365 1.218 1.792 1.792 0 001.219 2.222c.35.102.687.247 1.003.43a7.167 7.167 0 007.346 0 4.658 4.658 0 014.587 0 7.436 7.436 0 007.381 0 4.658 4.658 0 014.587 0 7.615 7.615 0 003.727 1.003 6.952 6.952 0 003.583-1.003 4.584 4.584 0 011.003-.43 1.792 1.792 0 00-1.003-3.44h.107z" fill="#fff"/></svg>
																			</div>
																			<div class="form__limit--text">
																				{{ $t('titles.create_new_limit_sub') }}
																			</div>
																			<button @click="chapter = 'republic'">
																				{{ $t('button.more') }}
																			</button>
																		</div>
																	</div>
																</div>
																<div class="col-lg-6">
																	<div class="form__checkbox--chapter">
																		<div class="form__limit--btn">
																			<div class="form__limit--icon">
																				<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.873 13.706c.35.103.688.248 1.004.43a7.167 7.167 0 007.346 0 4.659 4.659 0 014.586 0 7.435 7.435 0 007.382 0 4.66 4.66 0 014.587 0 7.614 7.614 0 003.726 1.004 6.951 6.951 0 003.584-1.004 4.589 4.589 0 011.003-.43 1.791 1.791 0 10-1.003-3.44 7.974 7.974 0 00-1.792.806 3.727 3.727 0 01-3.763 0 8.314 8.314 0 00-8.134 0 3.78 3.78 0 01-3.798 0 8.314 8.314 0 00-8.134 0 3.726 3.726 0 01-3.763 0 7.975 7.975 0 00-1.791-.806 1.792 1.792 0 00-1.004 3.44h-.036zm32.25 14.477a7.974 7.974 0 00-1.791.806 3.727 3.727 0 01-3.763 0 8.312 8.312 0 00-8.134 0 3.781 3.781 0 01-3.798 0 8.312 8.312 0 00-8.134 0 3.728 3.728 0 01-3.763 0 7.975 7.975 0 00-1.792-.806 1.79 1.79 0 10-1.003 3.44c.35.103.687.247 1.003.43a7.167 7.167 0 007.346 0 4.658 4.658 0 014.587 0 7.435 7.435 0 007.382 0 4.658 4.658 0 014.586 0 7.614 7.614 0 003.727 1.003 6.951 6.951 0 003.583-1.003 4.587 4.587 0 011.004-.43 1.791 1.791 0 10-1.004-3.44h-.036zm0-8.958a7.97 7.97 0 00-1.791.806 3.726 3.726 0 01-3.763 0 8.313 8.313 0 00-8.134 0 3.78 3.78 0 01-3.798 0 8.313 8.313 0 00-8.134 0 3.727 3.727 0 01-3.763 0 7.97 7.97 0 00-1.792-.806 1.792 1.792 0 00-2.365 1.218 1.792 1.792 0 001.219 2.222c.35.102.687.247 1.003.43a7.167 7.167 0 007.346 0 4.658 4.658 0 014.587 0 7.436 7.436 0 007.381 0 4.658 4.658 0 014.587 0 7.615 7.615 0 003.727 1.003 6.952 6.952 0 003.583-1.003 4.584 4.584 0 011.003-.43 1.792 1.792 0 00-1.003-3.44h.107z" fill="#fff"/></svg>
																			</div>
																			<div class="form__limit--text">
																				{{ $t('titles.create_new_limit_sub_2') }}
																			</div>
																			<button @click="chapter = 'local'">
																				{{ $t('button.more') }}
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left"></div>
										<div class="form__line--right">
											<div class="col-lg-12">
												<div
													class="form__block--line form__messages"
													v-if="message.status"
													:class="{
														'form__messages--error': message.status != 200,
														'form__messages--success': message.status == 200,
													}"
												>
													<label class="form__block--input">
														<span class="form__block--title">
															{{ message.text }}
														</span>
													</label>
												</div>
												<div
													class="form__block--line form__messages"
													v-if="errorMessage.text"
													:class="{
														'form__messages--error': errorMessage.status != 200,
														'form__messages--success': errorMessage.status == 200,
													}"
												>
													<label class="form__block--input">
														<span class="form__block--title">
															{{ errorMessage.text }}
														</span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

export default {
	data() {
		return {
			applications: null,
			chapter: null,

			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},
		}
	},

	computed: {},
	methods: {
		onSubmit(evt) {
			this.errorMessage = {
				status: null,
				messages: null,
				text: null,
			}
			this.message = {
				status: null,
				text: null,
			}
			const formData = new FormData(evt.target)
			api
				.post('limit/create', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message
						this.$router.push({
							path:
								'/' +
								this.$i18n.locale +
								'/account/limits/' +
								response.data.data.id +
								'/' +
								this.chapter,
						})
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					this.errorMessage.messages = error.response.data.error_messages
				})
		},
	},
	beforeMount() {},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
}
</script>
